<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.outTradeNo" class="number" clearable>
          <template slot="prepend">流水号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.status" placeholder="充值状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.type" placeholder="充值类型">
          <el-option
            v-for="item in typeOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button class="buy-btn" @click="toCharge">充值</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: { outTradeNo: ''},
      date: [],
      // 状态选项
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 'SUCCESS', label: '充值成功' },
        { value: 'WAITING', label: '待付款' },
        { value: 'FAILED', label: '充值失败' }
      ],
      // 支付类型选项
      typeOpts: [
        { value: '', label: '请选择' },
        // { value: '0', label: '未知支付类型' },
        { value: '1', label: '余额' },
        { value: '2', label: '星币' }
      ]
    };
  },
  methods: {
    searchList() {
      let [startTime = '', endTime = ''] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit('search', param);
    },
    toCharge() {
      this.$router.push({ path: '/charge' });
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .number.el-input {
    width: 260px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .el-select {
    width: 120px;
  }
  .time {
    width: 370px;
    padding: 3px 5px;
  }
  .buy-btn {
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
.form .time{
  width: 100%;
}
}
</style>

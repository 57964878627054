import API from "@/api";

export default {
  getList: params => {
    return API.requestGet(
      {
        params,
        url: "/api/merchant/pay/rechargeTrade",
        auth: true
      },
      false
    );
  }
};

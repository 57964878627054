<template>
  <div class="account-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
      :default-sort = "{prop: 'createdAt', order: 'descending'}"
      @sort-change="sortChange"
    >
      <el-table-column prop="tempId" label="ID" align="center"></el-table-column>
      <el-table-column prop="outTradeNo" label="流水号 (后8位)" align="center">
        <template slot-scope="scope">
          <span
            v-bind:title="scope.row.outTradeNo"
            class="cursor-default"
          >{{ scope.row.outTradeNo | tradeNo }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="充值金额(元)" align="center" sortable="custom">
        <template slot-scope="scope">{{scope.row.amount ? (scope.row.amount/100).toFixed(2) : 0}}</template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="购买时间"
        sortable="custom"
        align="center"
        show-overflow-tooltip
        min-width="155"
      ></el-table-column>
      <el-table-column label="支付方式" align="center">
        <template slot-scope="scope">{{ scope.row.payment | paymentText }}</template>
      </el-table-column>
      <el-table-column label="支付类型" align="center">
        <template slot-scope="scope">{{ scope.row.type | paymentType }}</template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status | chargeStatusColor"
          >{{ scope.row.status | chargeStatus }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    paymentType: p => {
        switch (p) {
            case 1:
                return "余额";
            case 2:
                return "星币";
            default:
                return "未知";
        }
    },
    paymentText: p => {
        switch (p) {
            case 1:
                return "支付宝";
            case 2:
                return "微信";
            default:
                return "未知";
        }
    },
    tradeNo: n => n.substr(-8),
    chargeStatus: s => {
        switch (s) {
            case 0:
                return "待付款";
            case 1:
                return "充值成功";
            case -1:
                return "充值失败";
            default:
                return "未知";
        }
    },
    chargeStatusColor: s => {
        switch (s) {
            case 0:
                return "info";
            case 1:
                return "success";
            case -1:
                return "danger";
            default:
                return "danger";
        }
    }
  },
  methods:{
    sortChange(val){
      this.$emit('sortChange',val)
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
.cursor-default {
  cursor: default !important;
}
</style>
